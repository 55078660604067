$(function() {

    let lastY = 0;
    $(window).scroll(function() {
        $('nav .collapse').collapse('hide')
        $('nav .dropdown-toggle').dropdown('hide')

        if ($(this).scrollTop() < 100) {
            $('nav').removeClass('hideup')
        } else {

            /* 顯示/隱藏 navbar */
            let scrollY = this.scrollY;
            if(scrollY > lastY) {
                $('nav').addClass('hideup')
            } else {
                $('nav').removeClass('hideup')
            }
            lastY = scrollY;
        }

        /* scrollTop 按鈕 */
        if ($(this).scrollTop() > 300) {
            $('#toTop').fadeIn();
        } else {
            $('#toTop').fadeOut();
        }
    });

    $('#toTop').click(function() {
        $('nav').removeClass('hideup')
        $('html, body').animate({scrollTop : 0},800);
        return false;
    });
})