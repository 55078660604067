<template>
    <div>
        <div class="row">
            <form id="test_form" class="col-12" :class="iframeSrc ? 'col-md-4' : 'col-lg-8 mx-auto'" ref="previewForm" @submit.prevent.stop="getPreview">

                <div>
                    <div v-if="formMessage" class="alert alert-warning mb-3" role="alert">
                        {{ formMessage }}
                    </div>
                </div>

                <div class="mb-2">
                    <div class="mb-2"><b><u>選擇檔案</u></b></div>

                    <label for="test_filename" class="w-100">
                        <input name="filename" type="file" id="test_filename" :disabled="isLoading" @change="changeFile">
                        <small class="d-block text-center">
                            <span v-if="inputFile">
                                <span v-if="isValidFile" class="text-success">
                                    <i class="fas fa-check-circle"></i> {{ inputFilename }}
                                </span>
                                <span v-else class="text-danger">
                                    <i class="fas fa-exclamation-circle"></i> {{ inputFilename }}
                                    （<span>不支援的檔案類型</span>）
                                </span>
                            </span>
                            <span v-else>
                                <i class="fas fa-cloud-upload-alt"></i> 選擇欲預覽的檔案
                            </span>
                        </small>
                    </label>
                </div>
                <div>
                    <div class="mb-2"><b><u>設定預覽參數</u></b></div>
                    <div class="form-group">
                        <label for="watermarkText">浮水印文字</label>
                        <textarea class="form-control" id="watermarkText" rows="2" name="watermarkText" placeholder="輸入浮水印文字"
                            :disabled="isLoading" v-model="defaultSettings['watermarkText']" />
                    </div>

                    <div class="form-group">
                        <label for="watermarkAngle">浮水印旋轉角度</label>
                        <input type="number" class="form-control" id="watermarkAngle" name="watermarkAngle" min="0" max="360" aria-describedby="angleHint"
                            :disabled="isLoading" :value="defaultSettings['watermarkAngle']" />
                        <small id="angleHint" class="form-text text-muted">有效數值：0~360 度</small>
                    </div>

                    <div class="form-check mb-3">
                        <input class="form-check-input" type="checkbox" id="disableExport" name="disableExport"
                            :disabled="isLoading" :checked="defaultSettings['disableExport']" />
                        <label class="form-check-label" for="disableExport">禁止下載</label>
                    </div>

                    <div class="form-check mb-3">
                        <input class="form-check-input" type="checkbox" value="" id="disablePrint" name="disablePrint"
                            :disabled="isLoading" :checked="defaultSettings['disablePrint']" />
                        <label class="form-check-label" for="disablePrint">禁止列印</label>
                    </div>

                    <div class="form-check mb-3">
                        <input class="form-check-input" type="checkbox" value="" id="disableCopy" name="disableCopy"
                            :disabled="isLoading" :checked="defaultSettings['disableCopy']" />
                        <label class="form-check-label" for="disableCopy">禁止複製內容</label>
                    </div>
                </div>

                <div>
                    <button type="submit" class="btn btn-warning pl-4 pr-4" :disabled="isLoading || !isValidFile">開始預覽</button>
                    <button type="reset" class="btn btn-link text-secondary pl-4 pr-4" :disabled="isLoading" @click="resetForm">重置</button>
                </div>

                <div v-if="isLoading" class="progress mt-3" id="pgbar-root">
                    <div id="pgbar" class="active progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                        style="width: 0%">
                    </div>
                </div>

            </form>
            <div v-if="iframeSrc" id="test_result" class="col-md-8 col-12 mx-auto mt-md-0 mt-5">
                <div class="mb-2 d-flex justify-content-between">
                    <span><b><u>預覽結果</u></b><small>（{{ iframeFilename }}）</small></span>
                    <span>
                        <a class="text-danger" alt="關閉預覽視窗"
                            @click.stop.prevent="closeIframe"
                            data-toggle="tooltip" data-placement="bottom" title="關閉預覽視窗"
                        >
                            <i class="fas fa-window-close"></i>
                        </a>
                    </span>
                </div>
                <iframe :src="iframeSrc" frameborder="0" class="h-100 w-100"></iframe>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '../../sass/_variables.scss';

    // upload file area
    form label[for="test_filename"] {
        height: 70px;
        line-height: 70px;
        border: none;
        border-radius: 0.25rem;
        background-color: lighten($gray, 55%);
        outline: 2px dashed lighten($gray, 25%);
        outline-offset: -10px;
        transition: outline-offset .15s ease-in-out;
        color: $gray;

        input#test_filename {
            opacity: 0;
            position: absolute;
            &:hover ~ div strong {
                text-decoration: underline;
                font-weight: 500;
            }
        }
        // .font-icon-file {
        //     display: none;
        //     font-size: 3.5rem;
        //     color: lighten($gray, 20%);
        // }
        // .font-icon-upload {
        //     display: block;
        //     font-size: 3.5rem;
        //     color: lighten($gray, 20%);
        // }
        .selected-filename {
            color: lighten($gray, 20%);
            font-size: 1.2rem;
            font-weight: 400;
            strong {
                color: lighten($gray, 20%);
                font-weight: 700;
            }
        }
    }

    iframe {
        box-shadow: 0px 3px 6px 3px rgb(0 0 0 / 14%);
        min-height: 800px;
    }
</style>

<script>
    export default {
        name: "preview",
        props: ['baseUrl', 'supportMimetype', 'defaultSettings'],
        data() {
            return {
                isWideWindow: null,
                isLoading: false,
                iframeSrc: null,
                iframeFilename: null,

                formAction: this.baseUrl + '/preview',
                formMessage: null,

                inputFile: null,
                inputFilename: null,
            }
        },
        watch: {
            "iframeSrc"(newVal, oldVal) {
                if (newVal !== oldVal) {
                    if (!newVal) {
                        window.removeEventListener('message', this.receiveMessage);
                    } else {
                        // 接收 iframe 傳來的訊息
                        window.addEventListener("message", this.receiveMessage, false);
                    }
                }
            },
        },
        computed: {
            isValidFile() {
                return this.inputFile ? this.supportMimetype.includes(this.inputFile?.type) : false;
            }
        },
        methods: {
            receiveMessage(event) {
                try {
                    const data = JSON.parse(event.data);
                    if (data.MessageId == 'UI_Close') {
                        this.closeIframe()
                    }
                } catch (error) {
                    // catch err
                }
            },

            /** 變更上傳檔案 */
            changeFile(e) {
                this.formMessage = null;
                this.inputFile = e?.target?.files[0] ?? null;
                this.inputFilename = this.inputFile ? this.inputFile.name : null;
            },

            /** 重置表單 & data */
            resetForm() {
                this.$refs.previewForm.reset();
                this.inputFile = null;
                this.inputFilename = null;
            },

            closeIframe() {
                this.iframeSrc = null;
                this.iframeFilename = null;
                this.scrollToEl()
            },

            getPreview() {
                const self = this;
                const fData = new FormData(document.getElementById("test_form"));
                if (!self.inputFile || fData.get("filename").name == "") {
                    self.formMessage = "請選擇檔案"
                    self.scrollToEl();
                    return;
                }
                if (!self.isValidFile) {
                    self.formMessage = "不支援的檔案類型"
                    self.scrollToEl();
                    return;
                }

                $.ajax({
                    xhr: function xhr() {
                        var xhr = new window.XMLHttpRequest();
                        xhr.upload.addEventListener("progress", function (evt) {
                            if (evt.lengthComputable) {
                                var percentComplete = evt.loaded / evt.total;
                                $('.progress').show();
                                $('#pgbar').css({
                                    width: (percentComplete) * 100 + '%'
                                }).html('上傳檔案中...');
                            }
                        }, false);
                        xhr.upload.addEventListener("load", function (evt) {
                            if (evt.lengthComputable) {
                                var percentComplete = evt.loaded / evt.total;
                                $('#pgbar').css({
                                    width: (percentComplete) * 100 + '%'
                                }).html('伺服器處理中...');
                            }
                        }, false);
                        return xhr;
                    },
                    beforeSend: function() {
                        self.isLoading = true;
                        self.formMessage = null;
                    },
                    url: self.formAction,
                    type: "POST",
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                    data: fData,
                    crossDomain: true,
                    processData: false,
                    contentType: false,
                    cache: false,
                    success: (res) => {
                        $('#pgbar').html('完成');
                        self.iframeSrc = res;
                        self.iframeFilename = self.inputFilename;
                        self.formMessage = null;
                        self.$nextTick(() => {
                            self.scrollToEl('#test_result');
                        })
                    },
                    error: (jqXHR, error, errorThrown) => {
                        $('#pgbar').css({width: '100%'}).html('錯誤');
                        self.iframeSrc = null;
                        self.iframeFilename = null;
                        self.formMessage = "預覽失敗";
                        if (jqXHR.status === 400) {
                            if (jqXHR?.responseText) {
                                // UI display error msg
                                self.formMessage += '（' + jqXHR.responseText + '）';
                            }
                        } else {
                            self.formMessage += '（預覽服務有誤，請稍候再試或聯絡我們）';
                            console.debug('Preview 失敗', jqXHR)
                        }
                    },
                    complete: () => {
                        $('.progress').hide();
                        $('#pgbar').css({width: '0%'}).html('');
                        self.isLoading = false;
                        self.resetForm();
                    }
                });
            },

            scrollToEl: (targetID = '#test_form') => {
                const DOMRect = document.querySelector(targetID)?.getBoundingClientRect();
                if (DOMRect?.y) {
                    $('html, body').animate({scrollTop: DOMRect.y}, 500);
                }
            }
        }
    }
</script>
