<template>
    <ul>
        <li v-for="(item, index) in restructList" :key="index">
            <div class="time">{{item.year}}</div>
            <div class="hr"></div>
            <div class="info">
                <ul>
                    <li v-for="(event, index) in item.events" :key="index">{{event}}</li>
                </ul>
            </div>
        </li>
    </ul>
</template>

<style lang="scss" scoped>
    @import '../../sass/_variables.scss';
    ul, li { /* reset */
        margin: 0;
        padding: 0;
        line-height: 1.8rem;
    }

    .timeline > ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 30px;

        & > li {
            max-width: 900px;
            display: flex;
            width: 100%;
            margin: 0 auto;
            padding: 12px 20px;
            color: #000;
            border-radius: 10px;
            line-height: 20px;
        }

        .time {
            color: $orange;
            font-size: 18px;
        }
        .hr {
            width: 2px;
            margin: 0 20px;
            background-color: $orange;
        }
        .info {
            ul li:not(:last-child) {
                margin-bottom: 5px;
            }
        }
    }
</style>

<script>
    export default {
        name: "timeline",
        props: ['historyList'],
        computed: {
            restructList :function() {
                let newList = new Array();
                this.historyList.forEach(
                    function (item) {
                        let index = newList.findIndex(x => x.year === item.year);
                        if ( index === -1 ) {
                            newList.push({
                                year: item.year,
                                events: [item.event]
                            })
                        } else {
                            newList[index].events.push(item.event)
                        }
                    }
                )
                newList.sort(function (a, b) {
                    return a.year < b.year ? 1 : -1;
                })
                return newList;
            }
        }
    }
</script>
